@charset "UTF-8";
/* pt-sans-regular - latin_cyrillic-ext */
@font-face {
    font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/pt-sans-v11-latin_cyrillic-ext-regular.eot");
  /* IE9 Compat Modes */
  src: local("PT Sans"), local("PTSans-Regular"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-regular.woff2") format("woff2"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-regular.woff") format("woff"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-regular.ttf") format("truetype"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-regular.svg#PTSans") format("svg");
  /* Legacy iOS */
}

/* pt-sans-italic - latin_cyrillic-ext */
@font-face {
    font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/pt-sans-v11-latin_cyrillic-ext-italic.eot");
  /* IE9 Compat Modes */
  src: local("PT Sans Italic"), local("PTSans-Italic"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-italic.woff2") format("woff2"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-italic.woff") format("woff"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-italic.ttf") format("truetype"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-italic.svg#PTSans") format("svg");
  /* Legacy iOS */
}

/* pt-sans-700 - latin_cyrillic-ext */
@font-face {
    font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/pt-sans-v11-latin_cyrillic-ext-700.eot");
  /* IE9 Compat Modes */
  src: local("PT Sans Bold"), local("PTSans-Bold"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-700.eot?#iefix") format("embedded-opentype"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-700.woff2") format("woff2"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-700.woff") format("woff"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-700.ttf") format("truetype"), url("../fonts/pt-sans-v11-latin_cyrillic-ext-700.svg#PTSans") format("svg");
  /* Legacy iOS */
}

/* RESET */
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

a {
    text-decoration: none;
    color: inherit;
    line-height: 1;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

/* =================================================== */
body {
    margin: 0;
    color: #1a1a1a;
    font-family: 'PT Sans', sans-serif;
    font-size: 19px;
    overflow-x: hidden;
}

.text-center {
    text-align: center;
}

.text-italic {
    font-style: italic;
}
.text-bold {
    font-weight: 700;
}

.container {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

.container--lg {
    max-width: 1000px;
}

.team {
    min-height: 70vh;
}
 
.lectures,
.practice,
.contacts {
    min-height: 90vh;
}

/* LOADER
==================================================== */
.loader {
    position: fixed;
    height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #1a1a1a;
}

.loader__img {
    width: 10em;
    height: 10em;
    margin-bottom: 1em;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-animation: scale .5s linear infinite;
    animation: scale .5s linear infinite;
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
}

.loader__text {
    text-align: center;
    font-size: 3.6em;
    line-height: 1.2;
    color: #fff;
}

@-webkit-keyframes scale {
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale {
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* PAGE
==================================================== */
.page {
    display: grid;
    grid-template-columns: repeat(1, 1fr 4fr);
    grid-auto-rows: 100vh;
  /* 	grid-auto-rows: minmax(100vh, auto); */
  /* АДАПТИВНАЯ ВЫСОТА ДЛЯ КОЛОНОК */
    justify-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  /* ПО УМОЛЧАНИЮ. РАБОТАЮТ ПРИМЕРНО ТАКЖЕ КАК И У ФЛЕКСОВ */
    -webkit-transition: opacity .5s linear;
    -o-transition: opacity .5s linear;
    transition: opacity .5s linear;
}

.grid-column {
    background-color: #1a1a1a;
}

.grid-column:last-child {
    overflow-y: auto;
    background-color: #fff;
}

/* ASIDE
==================================================== */
.aside__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 1em 0;
}

.logo__img {
    width: 100px;
    height: 100px;
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin: 2em 0 0;
    text-align: center;
    color: #F2F2F2;
    overflow: hidden;
}

.nav__link {
    padding: 1em 1em;
}

.nav__link:hover {
    color: #f0db4f;
}

.nav__link.active {
    background-color: #fff;
    color: #1a1a1a;
}

.burger {
    display: none;
    width: 40px;
    height: 40px;
    position: fixed;
    top: 2em;
    left: 2em;
    z-index: 999;
    cursor: pointer;
    outline: none;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transition: opacity .2s linear, -webkit-transform .2s linear;
    transition: opacity .2s linear, -webkit-transform .2s linear;
    -o-transition: transform .2s linear, opacity .2s linear;
    transition: transform .2s linear, opacity .2s linear;
    transition: transform .2s linear, opacity .2s linear, -webkit-transform .2s linear;
}

.burger:hover {
    opacity: .9;
}

.burger__icon {
    width: 20px;
    margin: auto;
}

.burger.active-burg {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* MAIN
==================================================== */
.content {
    padding: 3em 0 1em;
    text-align: justify;
}

.content > * {
    margin-bottom: 1em;
}

.content-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
}

.content-header__title {
    line-height: 1;
    font-size: 2.5em;
}

.publication__date {
    font-size: 14px;
    opacity: .7;
}

.publication__date--lg {
    font-size: 2em;
    font-weight: 700;
    color: #f0db4f;
}

.content__title {
    margin-bottom: .1em;
    font-size: 1.2em;
    line-height: 1.5em;
}

.content__list-item {
    margin-bottom: .5em;
}

.content__img {
    position: relative;
}

.content__img-link {
    padding: .8em 1em;
    position: absolute;
    border-bottom: 2px solid #fff;
    top: 77%;
    right: 0;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    font-size: 1.1em;
    opacity: .9;
    color: #fff;
    background-color: #1a1a1a;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}

.content__img-link:hover {
    padding-right: .3em;
    opacity: 1;
}

/* BORDER-BLOCK */
.border-block {
    padding: 1em 1em;
    text-align: left;
}

.border-block__list-item {
    margin-bottom: .5em;
    list-style: circle;
}

.border-block__list-item a {
    color: #167ac6;
    padding-bottom: .1rem;
    border-bottom: 1px solid transparent;
    transition: all .3s;
}

.border-block__list-item a:hover {
    color: #e91e63;
    border-bottom: 1px solid #e91e63;
}

/* LECTURE PRACTICE CARDS */
.cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr 1fr);
    grid-gap: 20px;
    justify-items: center;
}

.cards--full {
    grid-template-columns: repeat(1, 1fr);
}

.cards__item {
    padding: 1em;
    cursor: pointer;
    text-align: center;
    border-radius: 50% 20% / 10% 40%;
    background-color: #1a1a1a;
    color: #fff;
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
    -webkit-transition: -webkit-transform .2s linear;
    transition: -webkit-transform .2s linear;
    -o-transition: transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear, -webkit-transform .2s linear;
}

.cards--full > .cards__item {
    border-radius: 1em;
}

.cards__item:hover {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.cards__header {
    margin-bottom: 1em;
}

.cards__title {
    font-size: 1.7em;
}

.cards__subtitle {
    font-size: 1.2em;
}

.cards__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1em;
}

.cards__link {
    margin-bottom: .5em;
    text-decoration: underline;
}

.cards__link:after {
    content: "";
    display: inline-block;
    width: 33px;
    height: 8px;
    margin-left: 15px;
    background-image: url("../img/svg/link-arrow.svg");
    -webkit-transition: -webkit-transform .2s linear;
    transition: -webkit-transform .2s linear;
    -o-transition: transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear, -webkit-transform .2s linear;
}

.cards__link:hover.cards__link:after {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* CONTACTS */
.contacts__link {
    display: block;
}

/* .contacts__link:after {
    content: "";
    display: inline-block;
    width: 33px;
    height: 8px;
    margin-left: 5px;
    background-image: url("../img/svg/link-arrow.svg");
    -webkit-transition: -webkit-transform .2s linear;
    transition: -webkit-transform .2s linear;
    -o-transition: transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear, -webkit-transform .2s linear;
} */

.contacts__link:hover.contacts__link:after {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

.contacts__link-icon {
    width: 25px;
    height: 20px;
    vertical-align: top;
}

/* TEAM */
.team__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.team__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
    text-align: center;
}

.team__img {
    margin-bottom: .5em;
}

.team__name {
    margin-bottom: .1em;
    font-size: 1.2em;
    font-weight: 700;
    color: #1a1a1a;
}

.team__work {
    margin-bottom: .2em;
    padding: .2em .4em;
    font-size: .8em;
    font-weight: 700;
    background-color: #1a1a1a;
    color: #E8E8E8;
}

.team__about {
    margin-bottom: .2em;
    font-size: .9em;
    text-align: center;
}

.team__socials-icon {
    width: 20px;
    height: 25px;
    margin: 0 .2em;
    fill: #1a1a1a;
    -webkit-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.team__socials-icon:hover {
    fill: #034C8C;
}

/* HELPERS
==================================================== */
.bold-text {
    font-weight: 700;
}

.italic-text {
    font-style: italic;
}

.gold-text {
    color: #f8da59;
    font-weight: normal;
}

.padding-l {
    padding: 0 2em;
}

.mark-text {
    position: relative;
    text-transform: uppercase;
    color: #1a1a1a;
    background-color: #f8da59;
}
/* 
.mark-text::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 0;
    left: -8px;
    background-color: #1a1a1a;
}

.mark-text:hover.mark-text::before {
    background-color: red;
} */

.empty-state{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  
  text-align: center
}

.icon-wrap{
  width:144px;
  height: 144px;
  background: #F8F8F8;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* Empty state */
.sleeping-bus{
  
  svg{
    width: 77px;
    overflow: visible;
  }
  
  .bus{
    animation: 4s sleeping-animation cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    transform-origin: 28px 54px;
    .mouth{
      animation: 4s sleeping-animation-mouth cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
      transform-origin: 27px 40px;
    }
  }
  
  @keyframes sleeping-animation{
    0%, 90%, 100%{
      transform: scaleY(1)
    }
    35%, 40%{
      transform: scaleY(1.1)
    }
  }
  
  @keyframes sleeping-animation-mouth{
    0%, 90%, 100%{
      transform: scaleX(1) scaleY(1)
    }
    35%, 40%{
      transform: scaleX(.5) scaleY(1.5)
    }
  }
  
  .zs{
    .z{
      transform-origin: 27px 0;
      animation: zs 4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
      stroke-dasharray: 18px;
      animation-delay: 2s;
    }
    .second{
      animation-delay: 3s;
    }
    .third{
      animation-delay: 4s;
    }
  }
  
  @keyframes zs{
    0%{
      transform: translateY(-12px) translateX(0);
      stroke-dashoffset: 0;
      stroke-width: 2px;
    }
    25%{
      transform: translateY(-30px) translateX(-10px)
    }
    50%{
      transform: translateY(-40px) translateX(5px);
    }
    75%{
      transform: translateY(-50px) translateX(-5px);
    }
    100%{
      transform: translateY(-60px) translateX(0);
      stroke-dashoffset: 20px;
      stroke-width: 0px;
    }
  }
  
}

/* FOOTER
==================================================== */
/* .footer > .container {
    border-top: 2px solid #1a1a1a;
} */

.footer__dev {
    padding: 1em 0;
    text-align: right;
    color: #1a1a1a;
    font-size: 1rem;
    font-style: italic;
}

.footer__dev-author {
    padding: .1em .2em;
    background-color: #1a1a1a;
    color: #fff;
}

/* ==================MEDIA================== */
@media (max-width: 1200px) {
    body {
        font-size: 18px;
    }
}

@media (max-width: 991px) {
    body {
        font-size: 17px;
    }

    .container--lg {
        max-width: 700px;
    }

    .container {
        padding: 1.4rem;
    }

    .main {
        position: relative;
    }

    .page {
        grid-template-columns: repeat(1, 1fr);
        padding: 0;
    }

    .burger {
        display: block;
    }

    .aside {
        display: none;
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: 999;
        overflow: hidden;
    }

    .show {
        display: block;
    }

    .nav {
        width: 50%;
        font-size: 1.3em;
        text-align: center;
    }

    .nav__link {
        padding: 1em;
    }

    .cards {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1em;
    }

    .cards__item {
        position: relative;
        border-radius: 10px 100px / 120px;
    }

    .team__inner {
        margin: 0;
    }
}

@media (max-width: 768px) {
    body {
        font-size: 16px;
    }
    
    .logo {
        display: none;
    }

    .content-header__title {
        font-size: 2.2em;
    }

    .team__item {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        font-size: 1.3em;
    }

    .team__img {
        max-width: 80%;
    }
}

@media (max-width: 545px) {
    body {
        font-size: 15px;
    }

    .burger {
        top: 1em;
        left: 1em;
    }

    .content-header__title {
        font-size: 2em;
    }

    .team__item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

